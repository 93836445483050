@import url('https://fonts.cdnfonts.com/css/poppins');
@import url('https://fonts.cdnfonts.com/css/raleway-5');

:root{
    /*PRINCIPAL*/
    --principal-color : #125067;
    --principal-color-text : #e9edee;
    --shadow-principal-color : #1250672a;
    --navbar-color : #535878;

    /*SECONDARY*/
    --secondary-color : #e9edee;
    --secondary-color-text : #125067;

    /*TERTIARY*/
    --tertiary-color : #61b3d8;

    --alternative-color : #BB0000;
}

body{
    background: var(--secondary-color);
}
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid var(--principal-color); /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
html{
    scroll-behavior: smooth;
}
section[id]{
    scroll-margin-top: 120px;
}
.section-carousel{
    display: flex;
    width: 100%;
    height: 600px;
    background: var(--secondary-color);
}
.section-carousel img{
    width: 0px;
    flex-grow: 1;
    opacity: .8;
    transition: .5s ease;  
}
.section-carousel img:hover{
    cursor: crosshair;
    width: 650px;
    opacity: 1;
    filter: contrast(120%);
}
.section-nosotros{
    background: var(--principal-color);
    color: var(--principal-color-text);
    font-family: 'Poppins', sans-serif; 
}
.container-nosotros{
    display: flex;
}
.nosotros-datos{
    width: 55%;
    box-sizing: border-box;
    padding: 3rem;
}
.nosotros-text{
    white-space: pre-wrap;
    padding: 1rem;
    font-size: 1.5rem;
    text-align: justify;
    border: solid 5px var(--principal-color-text);
}
.nosotros-caracteristicas{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 45%;
    padding: 1.5rem 0;
}
.caracteristica-img{
    width: 7.5rem;
    height: 7.5rem;
    filter: brightness(1.1);
    mix-blend-mode: multiply;
}
.detalle-span{
    font-weight: 900;
    font-size: 2rem;
    /* padding: .5rem; */
}
.caracteristica-detalle{
    background: rgb(248, 247, 247);
    color: var(--secondary-color-text);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: .5rem;
    box-sizing: border-box;
    padding: 0 1.5rem;
    text-align: justify;
    width: 95%;
    min-height: 20rem;
    font-size: 1.5rem;
    border-radius: 10px;
}
.wave-servicios{
    background: var(--secondary-color);
}
.servicios-title, .contactanos-title{
    text-align: center;
    margin: 0rem auto;
    font-size: 4rem;
    color: var(--secondary-color-text);
    text-transform: uppercase;
}
.nosotros-title, .tarifas-title{
    color: var(--principal-color-text);
    text-align: center;
    margin: 0 auto;
    font-size: 4rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
}
.section-servicios{
    font-family: 'Poppins', sans-serif; 
    background: var(--secondary-color);
}
.servicio-span{
    text-align: center;
    font-weight: bold;
    color: var(--principal-color-text);
    font-size: 1.5rem;
    background: var(--principal-color);
    border-top-right-radius: 30px;
    width: 100%; 
    padding: .2rem .5rem; 
    box-sizing: border-box;
    box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.75); 
}
.servicio-text{
    text-align: start;
    width: 100%;
    background: var(--secondary-color);
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    border-bottom-left-radius: 30px;
    box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.75);
    font-size: 1.3rem;
}
.container-servicios{
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.servicio-datos{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: .5rem;
    box-sizing: border-box;
}

.servicio{
    width: 38rem;
    height: 50rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    margin-bottom: 4rem;
}
.servicio img{
    width: 85%;
    filter: brightness(1.1);
    mix-blend-mode: multiply;
}
.section-tarifas{
    text-align: center;
    background: var(--principal-color);
    padding: 3rem 0;
    color: var(--principal-color-text);
    margin-top: -10rem;
    font-family: 'Poppins', sans-serif; 
}
.horario-table{
    width: 85%;
}
.horario-table thead tr td{
    text-align: center;
}
.horario-cabecera, .horario-dia-hora{
    padding: .5rem;
}
.horario-sede{
    text-align: center;
}
.ubicanos-text{
    text-align: center;
    font-size: 3rem;
    font-family: 'Poppins', sans-serif; 
    color: var(--principal-color);
}
.icon-ver-mapa{
    color: var(--alternative-color);
    transition: .2s ease all;
}
.container-ubicaciones{
    background: var(--principal-color);
    display: flex;
    padding: 2rem;
    justify-content: space-evenly;
    margin-bottom: 2rem;
    flex-wrap: wrap;
}
.ubicacion{
    border-radius: 20px;
    background: var(--secondary-color);
    width: 25rem;
    height: 30rem;
    display: flex;
    text-align: justify;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    font-family: 'Poppins', sans-serif; 
    color: var(--secondary-color-text);
}
.ubicacion-span{
    font-size: 1.5rem;
}
.img-ubicacion{
    max-width: 100%;
    height: 15rem;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.ubicacion-resolucion{
    font-size: 1.5rem;
    padding: .75rem;
}
.btn-verMapa{
    border-radius: 20px;
    cursor: pointer;
    width: 100%;
    height: 2rem;
    font-family: 'Poppins', sans-serif; 
    outline: none;
    border: var(--alternative-color) 1.5px solid;
    transition: .2s ease all;
    font-weight: 600;
    background: var(--secondary-color);
}
.btn-verMapa:hover{
    background: var(--principal-color);
    color: var(--principal-color-text);
    border: none;
}
.btn-verMapa:hover > .icon-ver-mapa{
   color: white;
}
.link-mapa{
    width: 50%;
}
.columna-dias, .columna-hora{
    padding-top: 1rem;
}
.table{
    width: 90%;
    text-align: center;
    font-family: 'Poppins', sans-serif; 
    margin: 0 auto;
}
.vehiculo-columna{
    border-bottom: solid 1px var(--tertiary-color);
    display: flex;
    font-size: 1.75em;
    padding: .2rem .5rem;
    font-weight: 300;
}
.precio-columna{
    padding: 0 .5rem;
    font-size: 1.75rem;
    border-bottom: solid 1px var(--tertiary-color);
    font-weight: 300;
}
.cabecera-table{
    padding: .5rem;
    font-size: 1.1rem;
    background: var(--tertiary-color) ;
}
.btn-map{
    filter: drop-shadow(0 0.125rem 0.313rem rgba(0,0,0,.7));
    position: fixed;
    line-height: 55px;
    bottom: 17%;
    right: 1%;
    border-radius: 50px;
    opacity: .6;
    cursor: pointer;
    transition: all .1s ease;
    z-index: 999;
}
.btn-map:hover{
    transform: scale(1.1);
    opacity: 1;
}

.btn-wsp{
    filter: drop-shadow(0 0.125rem 0.313rem rgba(0,0,0,.7));
    position: fixed;
    line-height: 55px;
    bottom: 8%;
    right: 1%;
    border-radius: 50px;
    opacity: .6;
    cursor: pointer;
    transition: all .1s ease;
}
.btn-wsp img, .btn-map img{
    width: 4rem;
    height: 4rem;
}
.btn-wsp:hover{
    transform: scale(1.1);
    opacity: 1;
}
.section-contactenos{
    display: flex;
    flex-direction: column;
}
.container-contactanos{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    padding: 1.5rem 1rem;
    margin-top: 10px;
}
.contactanos-text{
    font-size: 1.2rem;
}
.contactanos-formulario-inputs{
    border-radius: 10px;
    width: 60%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 1.5rem;
    align-items: center;
    border-top: solid 2px var(--navbar-color) ;
    border-bottom: solid 2px var(--navbar-color) ;
}
.input-item{
    font-size: 1em;
    width: 70%;
    margin: .5rem 0;
    outline: none;
    height: 3.5rem;
    box-sizing: border-box;
    background: var(--shadow-principal-color);
    border: none;
    padding: 0 1rem;
    color: var(--secondary-color-text);
    font-family: 'Poppins','sans-serif';
}
.formulario-input{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem;
    width: 100%;
}
.span-input{
    font-size: 1.5em;
}
.btn-enviar{
    width: 25%;
    height: 3rem;
    font-size: 1.3rem;
    font-family: 'Raleway', sans-serif;
    font-weight: bolder;
    cursor: pointer;
    margin: .5rem;
    border: none;
    background-color: var(--principal-color);;
    color: var(--principal-color-text);
    transition: .2s all ease;
    border-radius: 10px;
    box-shadow: 0 .20rem .25rem black;
}
.btn-enviar:hover{
    background-color: var(--shadow-principal-color);;
}
@media (max-width: 1382px){
    .section-carousel{
        width: 100%;
        height: 400px;
        box-sizing: border-box;  
    }
    .btn-map, .btn-wsp{
        right: 3%;
    }
    .btn-wsp img, .btn-map img{
        width: 3rem;
        height: 3rem;
    }
    .caracteristica-img{
        width: 90px;
        height: 90px;
    }
    .caracteristica-detalle{
        font-size: .7rem;
    }
    .detalle-span{
        font-size: 1.2rem;
        font-weight: bold;
    }
    .btn-wsp img{
        width: 3rem;
        height: 3rem;
    }
    .nosotros-title, .contactanos-title{
        font-size: 2rem;
    } 
    .nosotros-title{
        margin: 1rem auto;
        width: 100%;
    }    
    .contactanos-text{
        padding: 0 1rem;
    }   
    .servicio{
        font-size: 1.1em;
        width: 100%;
        margin-bottom: .5rem;
    }
    .servicio img{
        width: 40%;
    }
    .servicio-span{
        width:100%;
    }
    .servicio-datos{
        width: 100%;
    }
    .caracteristica-detalle{
        margin: 1rem;
    }
    .formulario-input{
        width: 90%;
    }
    .contactanos-formulario-inputs{
        width: 80%;
    }
    .input-item{
        font-size: .75em;
        height: 2rem;
        width: 75%;
    }
    .span-input{
        font-size: 1rem;
    }
    .contactanos-text{
        text-align: center;
    }
    .vehiculo-columna, .precio-columna{
        font-size: 1rem;
    }
    .cabecera-table{
        font-size: .75rem;
    }
    .leaflet-container{
        border-radius: 20px;
    }
}
@media (max-width: 950px){
    .container-nosotros{
        flex-direction: column;
    }
    .ubicanos-text{
        font-size: 2rem;
    }
    .wave-contactanos{
        margin: 0;
    }
    .nosotros-datos{
        background: var(--principal-color);
        width: 100%;
        padding: .1rem;
    }
    .vehiculo-columna{
        text-align: start;
    }
    .container-ubicaciones{
        padding: .75rem .5rem;
        gap: 2rem;
    }
    .columna-dias, .columna-hora{
        padding: 0;
    }
    .nosotros-text{
        border: none;
        font-size: 1rem;
    }
    .ubicacion{
        height: 27rem;
    }
    .ubicacion-resolucion{
        font-size: .75rem;
    }
    .nosotros-caracteristicas{
        width: 100%;
        margin-bottom: 2.5rem;
    }
    .wave-nosotros{
        display: none;
    }
    .nosotros-caracteristica{
        width: 95%;
        margin: .5rem 0;
        box-shadow: 0px -11px 9px -8px rgba(0,0,0,0.39);
        -webkit-box-shadow: 0px -11px 9px -8px rgba(0,0,0,0.39);
        -moz-box-shadow: 0px -11px 9px -8px rgba(0,0,0,0.39);
        font-size: 1.3rem;
        gap: 1rem;
        padding: 0 .5rem;
    }
    .caracteristica-img{
        width: 150px;
        height: 150px;
    }
    .caracteristica-detalle{
        font-size: 1.56rem;
    }
    .section-tarifas{
        margin-top: -2rem;
    }
}

@media (max-width: 500px){
    .nosotros-datos{
        font-size: .6rem;
    }    
    .caracteristica-img{
        width: 60px;
        height: 60px;
    }
    .caracteristica-detalle{
        font-size: .8rem;
        height: auto;
    }
    .servicios-title, .contactanos-title, .tarifas-title, .nosotros-title{
        font-size: 2rem;
    }
    .servicio{
        font-size: .75rem;
        margin-bottom: 8rem;
        margin-left: 0;
        margin-right: 0;
        height: 33rem;
        padding: 0;
    }
    .servicio-text{
        font-size: 1.1rem;
        padding-right: .5rem;
        height: 18rem;
    }
    .servicio img{
        width: 80%;
    }
    .caracteristica-img{
        width: 30%;
    }
    .input-item{
        width: 60%;
    }
    .vehiculo-columna, .precio-columna{
        font-size: .75rem;
    }
    .contactanos-text{
        font-size: .75rem;
    }
}


